import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  CompanyAccessResponse,
  LoginRequest,
  LoginResponse,
  UserResponse,
  ForgotPasswordRequest,
  ResetPasswordRequest,
  ResetPasswordResponse,
  ForgotPasswordResponse,
} from "features/auth/api/types";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: "auth/login",
        method: "POST",
        body: credentials,
      }),
    }),
    changePassword: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: "auth/login",
        method: "POST",
        body: credentials,
      }),
    }),
    forgotPassword: builder.mutation<
      ForgotPasswordResponse,
      ForgotPasswordRequest
    >({
      query: (data) => ({
        url: "auth/esqueciMinhaSenha",
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation<
      ResetPasswordResponse,
      ResetPasswordRequest
    >({
      query: (data) => ({
        url: "auth/confirmarAlteracaoSenha",
        method: "POST",
        body: data,
      }),
    }),
    getUserCompanyAccess: builder.query<CompanyAccessResponse, void>({
      query: () => "auth/getAcessoUsuarioEmpresa",
    }),
    getUser: builder.query<UserResponse, void>({
      query: () => "auth/usuarioLogado",
    }),
  }),
});

export const {
  useLoginMutation,
  useChangePasswordMutation,
  useGetUserCompanyAccessQuery,
  useGetUserQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi;
