import { Store } from "@reduxjs/toolkit";

export const STORAGE_SYNC_KEY = "app_sync";
export const SELECTED_COMPANY_CHANGE = "SELECTED_COMPANY_CHANGE";

type SyncAction = {
  type: string;
  payload: any;
  timestamp: number;
  sourceTabId: string;
};

let storeInstance: Store;
const TAB_ID = Math.random().toString(36).substring(2, 15);
let lastProcessedTimestamp = 0;

export const initializeSync = (store: Store) => {
  storeInstance = store;
  setupTabSynchronization();
};

const setupTabSynchronization = () => {
  window.addEventListener("storage", (event) => {
    if (event.key === STORAGE_SYNC_KEY && event.newValue) {
      try {
        const action: SyncAction = JSON.parse(event.newValue);

        // Ignore if this event was from the current tab or already processed
        if (
          action.sourceTabId === TAB_ID ||
          action.timestamp <= lastProcessedTimestamp
        ) {
          return;
        }

        lastProcessedTimestamp = action.timestamp;
        handleSyncAction(action);
      } catch (error) {
        console.error("Error parsing sync action:", error);
      }
    }
  });
};

const handleSyncAction = (action: SyncAction) => {
  if (!storeInstance) {
    console.error("Store not initialized");
    return;
  }

  switch (action.type) {
    case SELECTED_COMPANY_CHANGE:
      storeInstance.dispatch({
        type: "selectedCompany/setSelectedCompany",
        payload: {
          ...action.payload,
          _fromSync: true,
        },
      });
      break;
    default:
      console.warn("Unknown sync action:", action.type);
  }
};

export const broadcastStateChange = (type: string, payload: any) => {
  const action: SyncAction = {
    type,
    payload,
    timestamp: Date.now(),
    sourceTabId: TAB_ID,
  };

  localStorage.setItem(STORAGE_SYNC_KEY, JSON.stringify(action));
};
