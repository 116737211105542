import { useMemo } from "react";
import { Company } from "features/auth/api/types";
import { removeMask } from "common/utils/formatters";

export const useCompanyFilter = (companies: Company[], searchTerm: string) => {
  return useMemo(() => {
    if (!companies || !searchTerm) {
      return companies;
    }

    const term = searchTerm.toLowerCase();
    const cleanedTerm = removeMask(term);

    return companies
      .map((access) => access)
      .filter((company) => {
        const cleanedCNPJ = removeMask(company.cnpj_empresa);
        const normalizedName = company.nome_fantasia?.toLowerCase() || "";
        const normalizedRazaoSocial = company.razao_social.toLowerCase();

        return (
          normalizedName.includes(term) ||
          normalizedRazaoSocial.includes(term) ||
          cleanedCNPJ.includes(cleanedTerm)
        );
      });
  }, [companies, searchTerm]);
};
