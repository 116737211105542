import { useNavigate } from "react-router-dom";
import { Menu, User } from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { setIsOpen } from "features/auth/slices/authSlice";
import Dropdown, { DropdownOption } from "common/components/Dropdown";
import LocationIndicator from "common/components/LocationIndicator";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  className?: string;
  onMenuClick: () => void;
}

const Header = ({ className, onMenuClick }: HeaderProps) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUserMenuChange = (value: string) => {
    switch (value) {
      case "profile":
        navigate("/user-profile");
        break;
      case "logout":
        dispatch(setIsOpen(true));
        break;
    }
  };

  const userMenuOptions: DropdownOption[] = [
    {
      value: "profile",
      label: t("dashboard.header.userMenu.editProfile"),
    },
    {
      value: "logout",
      label: t("dashboard.header.userMenu.logout"),
    },
  ];

  const UserButton = () => (
    <div className="flex items-center gap-2 py-1">
      <div className="w-8 h-8 bg-light-bg rounded-full flex items-center justify-center flex-shrink-0">
        <User className="w-5 h-5 text-text" />
      </div>
      <span className="text-sm text-text truncate whitespace-nowrap max-w-[100px] md:max-w-[150px]">
        {user?.nome_usuario}
      </span>
    </div>
  );

  return (
    <header
      className={`h-16 bg-light-bg border-b-2 border-light-border px-4 flex items-center fixed top-0 z-10 w-full ${className} lg:w-[calc(100%-280px)]`}
    >
      {/* Left section with menu and location */}
      <div className="flex items-center gap-2 flex-1 min-w-0">
        <button
          onClick={onMenuClick}
          className="p-2 rounded-lg hover:bg-light-bg lg:hidden"
        >
          <Menu className="w-6 h-6 text-text" />
        </button>
        <LocationIndicator className="min-w-0 flex-1" />
      </div>

      {/* Right section with user dropdown */}
      <div className="flex-shrink-0 w-auto sm:w-52">
        <Dropdown
          options={userMenuOptions}
          onChange={handleUserMenuChange}
          renderOption={(option) => (
            <span className="text-sm">{option.label}</span>
          )}
          className="bg-primary-light rounded-lg"
          variant="light"
          placeholder={<UserButton />}
        />
      </div>
    </header>
  );
};

export default Header;
