import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import { CompanySegmentResponse, CompanyTypeResponse } from "./types";

export const companyApi = createApi({
  reducerPath: "companyApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getCompanyType: builder.query<CompanyTypeResponse, void>({
      query: () => "empresa/getTipoEmpresa",
    }),
    getCompanySegment: builder.query<CompanySegmentResponse, void>({
      query: () => "empresa/getSegmentoEmpresa",
    }),
  }),
});

export const { useGetCompanyTypeQuery, useGetCompanySegmentQuery } = companyApi;
