import { useNavigate } from "react-router-dom";
import Button from "common/components/Button";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-[60vh] p-4">
      <h1 className="text-3xl font-semibold text-primary mb-4">
        404 - Página não encontrada
      </h1>
      <p className="text-text-secondary mb-8 text-center max-w-md">
        A página que você está procurando não existe ou está em desenvolvimento.
      </p>
      <div className="flex gap-4">
        <Button onClick={() => navigate(-1)}>Voltar</Button>
        <Button variant="primary" onClick={() => navigate("/home")}>
          Ir para Dashboard
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
