import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationPTBR from "i18/locales/pt-BR/translation.json";

const resources = {
  "pt-BR": {
    translation: translationPTBR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "pt-BR",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
