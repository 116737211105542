import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

export interface NavItem {
  name: string;
  path: string;
  subItems?: Omit<NavItem, "icon">[];
}

interface MenuItem {
  menu_id: number;
  menu_superior_id?: number;
  nome_menu: string;
  caminho_menu?: string;
}

interface UseSidebarNavigationReturn {
  navItems: NavItem[];
  openDropdowns: Record<string, boolean>;
  toggleDropdown: (path: string) => void;
  getNavLinkClass: (props: { isActive: boolean }) => string;
  isDropdownOpen: (path: string) => boolean;
}

export const useSidebarNavigation = (): UseSidebarNavigationReturn => {
  const [openDropdowns, setOpenDropdowns] = useState<Record<string, boolean>>(
    {},
  );

  const menuProfile = useSelector(
    (state: RootState) => state.auth.user?.perfil.perfil_menu,
  );

  const buildNavItems = (menuItems: MenuItem[] = []): NavItem[] => {
    const topLevelItems = menuItems.filter((item) => !item.menu_superior_id);

    return topLevelItems.map((item) => {
      const subItems = menuItems.filter(
        (subItem) => subItem.menu_superior_id === item.menu_id,
      );

      const navItem: NavItem = {
        name: item.nome_menu,
        path: item.caminho_menu || "#",
      };

      if (subItems.length > 0) {
        navItem.subItems = subItems.map((subItem) => ({
          name: subItem.nome_menu,
          path: subItem.caminho_menu || "#",
        }));
      }

      return navItem;
    });
  };

  const toggleDropdown = (path: string): void => {
    setOpenDropdowns((prev) => ({ ...prev, [path]: !prev[path] }));
  };

  const getNavLinkClass = ({ isActive }: { isActive: boolean }): string => {
    return `flex items-center py-2 px-4 text-base ${
      isActive ? "text-primary font-medium" : "text-text hover:text-primary"
    } transition-colors`;
  };

  const isDropdownOpen = (path: string): boolean => {
    return !!openDropdowns[path];
  };

  const navItems = buildNavItems(menuProfile);

  return {
    navItems,
    openDropdowns,
    toggleDropdown,
    getNavLinkClass,
    isDropdownOpen,
  };
};
