import { lazy } from "react";
import { RouteType } from "router/types";

const protectedRoutes: Array<RouteType> = [
  {
    key: "home",
    path: "/home",
    component: lazy(() => import("features/home")),
    authority: "authenticated",
    meta: {
      layout: "default",
    },
  },
  {
    key: "user.profile",
    path: "/user-profile",
    component: lazy(
      () => import("features/userProfile/components/EditProfile"),
    ),
    authority: "authenticated",
    meta: {
      layout: "default",
    },
  },
  {
    key: "user.settings",
    path: "/user-profile/settings",
    component: lazy(
      () => import("features/userProfile/components/UserSettings"),
    ),
    authority: "authenticated",
    meta: {
      layout: "default",
    },
  },
];

export default protectedRoutes;
