export const removeMask = (value: string): string => {
  return value.replace(/[^\d]/g, "");
};

export const formatCNPJ = (cnpj: string): string => {
  const cleaned = removeMask(cnpj);
  return cleaned.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    "$1.$2.$3/$4-$5",
  );
};
