interface Config {
  apiGateway: {
    URL: string;
  };
  clientInfo: {
    APP_URL: string;
  };
}

export const loc: Config = {
  apiGateway: {
    URL: "http://localhost:5000/api/v1/",
  },
  clientInfo: {
    APP_URL: "http://localhost:3000/",
  },
};

export const dev: Config = {
  apiGateway: {
    URL: "https://apidev.smartpdi.com.br/api/v1/",
  },
  clientInfo: {
    APP_URL: "https://homologacao.smartpdi.com.br/",
  },
};

export const prod: Config = {
  apiGateway: {
    URL: "https://api.smartpdi.com.br/api/v1/",
  },
  clientInfo: {
    APP_URL: "https://app.smartpdi.com.br/",
  },
};

let config: Config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

switch (process.env.REACT_APP_STAGE) {
  case "loc":
    config = loc;
    break;
  case "prod":
    config = prod;
    break;
  default:
    config = dev;
}

export const API_URL = config.apiGateway.URL;
export const APP_URL = config.clientInfo.APP_URL;
