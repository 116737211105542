import { ErrorResponse } from "features/auth/api/types";
import { useTranslation } from "react-i18next";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  size?: "sm" | "md" | "lg" | "xl";
  className?: string;
  isLoading?: boolean;
  error?: ErrorResponse | null;
  loadingMessage?: string;
  closeOnBackdropClick?: boolean;
}

const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  footer,
  size = "md",
  className = "",
  isLoading = false,
  error = null,
  loadingMessage,
  closeOnBackdropClick = true,
}: ModalProps) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  const getErrorMessage = (status: number) => {
    return t(`modal.errors.${status}`) || t("modal.errors.default");
  };

  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget && closeOnBackdropClick) {
      onClose();
    }
  };

  const sizeClasses = {
    sm: "max-w-md",
    md: "max-w-lg",
    lg: "max-w-xl",
    xl: "max-w-2xl",
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/50"
      onClick={handleBackdropClick}
    >
      <div
        className={`relative w-full ${sizeClasses[size]} rounded-lg bg-white p-6 shadow-lg ${className}`}
        role="dialog"
        aria-modal="true"
      >
        {/* Header */}
        <div className="mb-4 flex items-center justify-center">
          {title && (
            <h2 className="text-3xl font-bold text-primary">{title}</h2>
          )}
        </div>

        {/* Content with state handling */}
        <div className="mb-6">
          {isLoading ? (
            <div className="flex items-center justify-center py-8">
              <p className="text-text-secondary">
                {loadingMessage || t("modal.loading")}
              </p>
            </div>
          ) : error ? (
            <div className="rounded-lg bg-error-bg p-4 text-error">
              <p className="text-center">{getErrorMessage(error.statusCode)}</p>
            </div>
          ) : (
            children
          )}
        </div>

        {/* Footer */}
        {!isLoading && !error && footer && (
          <div className="mt-6 flex justify-center gap-4">{footer}</div>
        )}
      </div>
    </div>
  );
};

export default Modal;
