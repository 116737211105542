import { lazy } from "react";
import { RouteType } from "router/types";
import { MenuItem } from "features/auth/api/types";

const getComponentPath = (menuPath: string): string => {
  const cleanPath = menuPath.replace(/^\//, "");

  const pathMap: Record<string, string> = {
    usuarios: "users/components/UsersList",
    empresas: "companies/components/CompaniesList",
    "usuario/perfil": "users/components/Profile",
  };

  return pathMap[cleanPath] || cleanPath;
};

export const mapMenuToRoutes = (menuItems: MenuItem[] = []): RouteType[] => {
  return menuItems
    .filter((item): item is MenuItem & { caminho_menu: string } =>
      Boolean(item.caminho_menu),
    )
    .map((item) => {
      const componentPath = getComponentPath(item.caminho_menu);

      return {
        key: `menu.${item.menu_id}`,
        path: item.caminho_menu,
        component: lazy(() =>
          import(`features/${componentPath}`).catch(
            () => import("router/components/NotFound"),
          ),
        ),
        authority: "authenticated",
        meta: {
          layout: "default",
        },
      };
    });
};
