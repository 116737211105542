import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import UserCompany from "common/components/Dashboard/components/Sidebar/components/UserCompany";
import { useSidebarNavigation, NavItem } from "./hooks/useSidebarNavigation";
import { useTranslation } from "react-i18next";

const SidebarContent = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { navItems, toggleDropdown, getNavLinkClass, isDropdownOpen } =
    useSidebarNavigation();
  const location = useLocation();

  useEffect(() => {
    onClose();
  }, [location.pathname, onClose]);

  const renderNavItem = (item: NavItem) => {
    const hasSubItems =
      Array.isArray(item.subItems) && item.subItems.length > 0;

    if (hasSubItems) {
      const isActive = location.pathname.startsWith(item.path);
      return (
        <div key={item.path}>
          <button
            type="button"
            className={`w-full flex items-center justify-between py-2 px-4 ${
              isActive ? "text-primary" : "text-text hover:text-primary"
            } transition-colors`}
            onClick={() => toggleDropdown(item.path)}
          >
            <div className="flex items-center gap-3">
              <span>{item.name}</span>
            </div>
            <svg
              className={`w-4 h-4 transition-transform duration-200 ${
                isDropdownOpen(item.path) ? "rotate-180" : ""
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          <div className={`${isDropdownOpen(item.path) ? "block" : "hidden"}`}>
            {item.subItems?.map((subItem) => (
              <NavLink
                key={subItem.path}
                to={subItem.path}
                className={({ isActive }) =>
                  `block py-2 px-4 pl-12 ${
                    isActive
                      ? "text-primary font-medium"
                      : "text-text hover:text-primary"
                  } transition-colors`
                }
              >
                {subItem.name}
              </NavLink>
            ))}
          </div>
        </div>
      );
    }

    return (
      <NavLink key={item.path} to={item.path} className={getNavLinkClass} end>
        {() => (
          <div className="flex items-center gap-3">
            <span>{item.name}</span>
          </div>
        )}
      </NavLink>
    );
  };

  return (
    <div className="h-full flex flex-col">
      <UserCompany />
      <nav className="flex-1 overflow-y-auto py-2">
        <NavLink to="/home" className={getNavLinkClass} end>
          {() => (
            <div className="flex items-center gap-3">
              <span>{t("dashboard.navigation.home")}</span>
            </div>
          )}
        </NavLink>
        {navItems.map(renderNavItem)}
      </nav>
    </div>
  );
};

export default SidebarContent;
