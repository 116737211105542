import Modal from "common/components/Modal";
import { logout, setIsOpen } from "features/auth/slices/authSlice";
import { clearSelectedCompany } from "features/auth/slices/selectedCompanySlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface LogoutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LogoutModal = ({ isOpen, onClose }: LogoutModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("selectedCompany");
    dispatch(clearSelectedCompany());
    dispatch(logout());
    dispatch(setIsOpen(false));
    navigate("/");
  }, [navigate, dispatch]);

  const modalFooter = (
    <>
      <button
        onClick={onClose}
        className="px-6 py-2 text-sm font-medium text-text hover:bg-primary-hover-light rounded-lg"
      >
        Não
      </button>
      <button
        onClick={handleLogout}
        className="px-6 py-2 text-sm font-medium text-primary bg-primary-light hover:bg-primary-hover-light rounded-lg"
      >
        Sim
      </button>
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("auth.logout.title")}
      size="sm"
      footer={modalFooter}
    >
      <p className="text-center text-text-secondary">
        {t("auth.logout.message")}
      </p>
    </Modal>
  );
};

export default LogoutModal;
