import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMemo, Suspense } from "react";
import { RootState } from "../store";
import { staticRoutes } from "./routes";
import { mapMenuToRoutes } from "./utils/routeUtils";
import Dashboard from "common/components/Dashboard";
import NotFound from "router/components/NotFound";

const Router = () => {
  const menuProfile = useSelector(
    (state: RootState) => state.auth.user?.perfil.perfil_menu,
  );
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );

  const dynamicRoutes = useMemo(
    () => mapMenuToRoutes(menuProfile || []),
    [menuProfile],
  );

  const allRoutes = [...staticRoutes, ...dynamicRoutes];

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />

      {/* Public Routes */}
      {allRoutes
        .filter((route) => route.authority === "public")
        .map(({ key, path, component: Component }) => (
          <Route
            key={key}
            path={path}
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Component />
              </Suspense>
            }
          />
        ))}

      {/* Protected Routes with Dashboard Layout */}
      <Route
        element={
          isAuthenticated ? (
            <Dashboard>
              <Outlet />
            </Dashboard>
          ) : (
            <Navigate to="/login" replace />
          )
        }
      >
        {allRoutes
          .filter((route) => route.authority === "authenticated")
          .map(({ key, path, component: Component }) => (
            <Route
              key={key}
              path={path}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <Component />
                </Suspense>
              }
            />
          ))}

        {/* Catch all route for authenticated area */}
        <Route path="*" element={<NotFound />} />
      </Route>

      {/* Global catch all route */}
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export default Router;
