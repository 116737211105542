import { RefreshCcw } from "lucide-react";
import Button from "common/components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setIsOpen } from "features/auth/slices/selectedCompanySlice";
import { useTranslation } from "react-i18next";
import { formatCNPJ } from "common/utils/formatters";

const UserCompany = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const selectedCompany = useSelector(
    (state: RootState) => state.selectedCompany.selectedCompany,
  );

  return (
    <div className="px-4 flex flex-col gap-3 justify-center items-center">
      <div className="w-full flex items-center justify-center">
        <img
          src={user?.empresa_logada.logo_empresa}
          alt="Company Logo"
          className="h-full w-auto object-contain"
        />
      </div>

      <div className="space-y-0.5">
        <p className="break-words uppercase font-medium text-base">
          {user?.empresa_logada?.razao_social} -{" "}
          {selectedCompany?.cnpj_empresa && (
            <span>{formatCNPJ(selectedCompany.cnpj_empresa)}</span>
          )}
        </p>
        <p className="text-sm">{user?.empresa_logada?.endereco}</p>
        <p className="text-sm">
          {user?.empresa_logada?.municipio_ibge.nome_municipio} -{" "}
          {user?.empresa_logada?.municipio_ibge.uf_ibge.sigla_uf}
        </p>
      </div>

      <Button
        variant="primary"
        onClick={() => dispatch(setIsOpen(true))}
        className="text-sm flex items-center justify-center gap-2 w-2/3"
      >
        <RefreshCcw className="w-4 h-4" />
        <span>{t("dashboard.userCompany.changeCompany")}</span>
      </Button>
    </div>
  );
};

export default UserCompany;
