import { useTranslation } from "react-i18next";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  variant?: "primary" | "link";
}

const Button = ({
  children = undefined,
  loading,
  variant = "primary",
  className = "",
  ...props
}: ButtonProps) => {
  const { t } = useTranslation();

  const baseStyles = {
    primary: "bg-primary text-white hover:bg-primary-hover",
    link: "bg-transparent text-primary hover:text-primary-hover p-0",
  };

  const buttonText = children || t("button.confirm");

  return (
    <button
      className={`rounded-md px-4 py-2 font-medium transition-colors ${baseStyles[variant]} ${loading ? "opacity-70" : ""} ${className}`}
      disabled={loading}
      {...props}
    >
      {loading ? t("button.loading") : buttonText}
    </button>
  );
};

export default Button;
