import { X } from "lucide-react";
import SidebarContent from "common/components/Dashboard/components/Sidebar/components/SidebarContent";

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar = ({ isOpen, onClose }: SidebarProps) => {
  return (
    <>
      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-dark-bg/30 z-40 lg:hidden"
          onClick={onClose}
        />
      )}

      {/* Sidebar */}
      <aside
        className={`fixed inset-y-0 left-0 w-[280px] bg-light-bg transform transition-transform duration-300 ease-in-out z-50 border-r-2 
          ${isOpen ? "translate-x-0" : "-translate-x-full"} 
          lg:translate-x-0 lg:static lg:z-30`}
      >
        <button
          onClick={onClose}
          className="absolute right-4 top-4 p-1 hover:bg-light-bg rounded-lg lg:hidden"
          aria-label="Close menu"
        >
          <X className="w-5 h-5 text-text" />
        </button>
        <SidebarContent onClose={onClose} />
      </aside>
    </>
  );
};

export default Sidebar;
