import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserQuery } from "features/auth/api";
import { RootState } from "store";
import { ErrorResponse, UserResponse } from "features/auth/api/types";
import { setUser } from "features/auth/slices/authSlice";
import Sidebar from "common/components/Dashboard/components/Sidebar";
import Header from "common/components/Dashboard/components/Header";
import UserCompanyAccessModal from "features/auth/components/Login/components/UserCompanyAccessModal";
import { setIsOpen } from "features/auth/slices/selectedCompanySlice";
import LogoutModal from "features/auth/components/Login/components/LogoutModal";
import { setIsOpen as setIsLogoutModalOpen } from "features/auth/slices/authSlice";
import { useTranslation } from "react-i18next";

const MOCK_DATA: UserResponse = {
  mensagem: "Usuario Logado: ",
  data: {
    usuario_id: 1,
    nome_usuario: "Marlon Fernando Dirksen",
    email_usuario: "exemplo@email.com",
    administrador_plataforma: true,
    empresa_logada: {
      empresa_id: 1,
      grupo_empresarial: {
        grupo_empresarial_id: 1,
        nome_grupo_empresarial: "CEMIG",
      },
      tipo_empresa: {
        tipo_empresa_id: 1,
        nome: "Empresa",
      },
      segmento: {
        segmento_id: 1,
        nome_segmento: "Geração",
      },
      razao_social: "Companhia Energética de Minas Gerais",
      nome_fantasia: "CEMIG",
      endereco: "Rua dos Bobos, 0",
      numero: "0",
      bairro: "Centro",
      municipio_ibge: {
        municipio_id: 1053,
        nome_municipio: "Blumenau",
        uf_ibge: {
          uf_id: 52,
          descricao_uf: "Santa Catarina",
          sigla_uf: "SC",
        },
      },
      logo_empresa: "https://files-dev.smartpdi.com.br/logos/logo-cemig.png",
    },
    perfil: {
      perfil_id: 1,
      nome_perfil: "Gestor PDI",
      perfil_menu: [
        {
          menu_id: 1,
          nome_menu: "Cadastro",
        },
        {
          menu_id: 2,
          menu_superior_id: 1,
          nome_menu: "Cadastro de usuários",
          caminho_menu: "/usuarios",
        },
        {
          menu_id: 3,
          menu_superior_id: 1,
          nome_menu: "Cadastro de empresa",
          caminho_menu: "/empresas",
        },
        {
          menu_id: 4,
          menu_superior_id: 1,
          nome_menu: "Cadastro de Perfil",
          caminho_menu: "/usuario/perfil",
        },
      ],
      perfil_permissoes: [
        {
          permissao_id: 3,
          nome_permissao: "Inserir rol",
        },
        {
          permissao_id: 3,
          nome_nome_permissao: "Convidar usuários",
        },
      ],
    },
  },
};

const Dashboard = ({ children }: React.PropsWithChildren) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const CONFIG = {
    USE_MOCK: true,
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const {
    data: userData,
    isLoading: isLoadingUser,
    isError: isErrorUser,
    error: errorUser,
  } = useGetUserQuery(undefined, { skip: CONFIG.USE_MOCK });

  const { isOpen } = useSelector((state: RootState) => state.selectedCompany);
  const { isOpen: isLogoutModalOpen } = useSelector(
    (state: RootState) => state.auth,
  );

  const currentData = CONFIG.USE_MOCK ? MOCK_DATA : userData;

  useEffect(() => {
    if (currentData) {
      dispatch(setUser(currentData.data));
    }
  }, [currentData]);

  const handleMenuClick = useCallback(() => {
    setIsSidebarOpen(true);
  }, []);

  const handleSidebarClose = useCallback(() => {
    setIsSidebarOpen(false);
  }, []);

  return (
    <div className="flex min-h-screen">
      {/* Loading */}
      {isLoadingUser && <div>{t("dashboard.loading")}</div>}

      {/* Error */}
      {isErrorUser && (errorUser as ErrorResponse).statusCode && (
        <div>{t("dashboard.errors.fetchUser")}</div>
      )}

      <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarClose} />
      <div className="flex-1 flex flex-col w-full">
        <Header
          className="left-0 lg:left-[280px]"
          onMenuClick={handleMenuClick}
        />
        <main className="flex-1 p-4 mt-24">{children}</main>
      </div>

      {/* Modals */}
      <UserCompanyAccessModal
        isOpen={isOpen}
        onClose={() => dispatch(setIsOpen(false))}
      />
      <LogoutModal
        isOpen={isLogoutModalOpen}
        onClose={() => dispatch(setIsLogoutModalOpen(false))}
      />

      {/* Logout Confirmation Modal */}
      <LogoutModal
        isOpen={isLogoutModalOpen}
        onClose={() => dispatch(setIsLogoutModalOpen(false))}
      />
    </div>
  );
};

export default Dashboard;
