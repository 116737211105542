import { Search } from "lucide-react";
import { useCompanySelection } from "./hooks/useCompanySelection";
import Modal from "common/components/Modal";
import Input from "common/components/Input";
import { Company, ErrorResponse } from "features/auth/api/types";
import { useTranslation } from "react-i18next";

interface UserCompanyAccessModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const UserCompanyAccessModal = ({
  isOpen,
  onClose,
}: UserCompanyAccessModalProps) => {
  const { t } = useTranslation();
  const {
    companies,
    handleCompanySelect,
    isLoading,
    isError,
    error,
    searchTerm,
    setSearchTerm,
  } = useCompanySelection(isOpen, onClose);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("auth.login.companySelection.title")}
      size="xl"
      isLoading={isLoading}
      error={isError ? (error as ErrorResponse) : null}
      loadingMessage={t("auth.login.companySelection.loading")}
    >
      <div className="space-y-6">
        <Input
          placeholder={t("auth.login.companySelection.searchPlaceholder")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          icon={<Search className="h-5 w-5" />}
        />
        <div className="overflow-auto max-h-96">
          <table className="w-full">
            <thead className="bg-gray-50 sticky top-0">
              <tr>
                <th className="px-6 py-3 text-left text-sm font-medium text-text">
                  {t("auth.login.companySelection.table.company")}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-text-secondary">
              {companies.map((company: Company) => (
                <tr
                  key={company.empresa_id}
                  onClick={() => {
                    handleCompanySelect(company);
                    onClose();
                  }}
                  className="cursor-pointer hover:bg-primary-hover-light transition-colors"
                >
                  <td className="px-6 py-4">
                    <div>
                      <div className="text-sm font-medium text-text">
                        {company.nome_fantasia || company.razao_social}
                      </div>
                      <div className="text-sm text-text-secondary">
                        {t("auth.login.companySelection.table.cnpj")}
                        {company.cnpj_empresa}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default UserCompanyAccessModal;
