import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "config/apiConfig";

export const baseQueryWithAuth = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    const selectedCompanyId = localStorage.getItem("x-codigo-empresa");
    if (selectedCompanyId) {
      headers.set("x-codigo-empresa", selectedCompanyId);
    }

    return headers;
  },
});
