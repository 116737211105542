import { EntityTransformers } from "features/crud/api/types";

// Registry of entity transformers
export const entityTransformers: EntityTransformers = {};

// Function to register entity transformers
export const registerEntityTransformer = (
  entityType: string,
  transformer: EntityTransformers[string],
) => {
  entityTransformers[entityType] = transformer;
};
