import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface LocationConfig {
  mainPath: string;
  label: string;
  displayName?: string;
  subpaths?: Record<string, string>;
}

interface LocationIndicatorProps {
  className?: string;
}

const LocationIndicator = ({ className = "" }: LocationIndicatorProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const menuProfile = useSelector(
    (state: RootState) => state.auth.user?.perfil.perfil_menu,
  );

  const staticLocationConfigs: Record<string, LocationConfig> = {
    home: {
      mainPath: "/home",
      label: t("locationIndicator.overview"),
      displayName: t("locationIndicator.dashboard"),
    },
    "user-profile": {
      mainPath: "/user-profile",
      label: t("locationIndicator.userProfile.main"),
      subpaths: {
        "/user-profile": t("locationIndicator.userProfile.edit"),
        "/user-profile/settings": t("locationIndicator.userProfile.settings"),
      },
    },
  };

  const dynamicLocationConfigs = useMemo(() => {
    const configs: Record<string, LocationConfig> = {};

    if (!menuProfile) return configs;

    // Create a map of parent menus
    const parentMenus = menuProfile.reduce(
      (acc, item) => {
        if (!item.menu_superior_id) {
          acc[item.menu_id] = item.nome_menu;
        }
        return acc;
      },
      {} as Record<number, string>,
    );

    // Process all menu items
    menuProfile.forEach((item) => {
      if (item.caminho_menu) {
        const pathParts = item.caminho_menu.split("/").filter(Boolean);
        const pathSegment = pathParts[0];

        if (!pathSegment) return;

        const parentMenu = item.menu_superior_id
          ? parentMenus[item.menu_superior_id]
          : item.nome_menu;

        configs[pathSegment] = {
          mainPath: `/${pathSegment}`,
          label: parentMenu,
          subpaths: {
            ...(configs[pathSegment]?.subpaths || {}),
            [item.caminho_menu]: item.nome_menu,
          },
        };
      }
    });

    return configs;
  }, [menuProfile]);

  const allLocationConfigs = useMemo(
    () => ({
      ...staticLocationConfigs,
      ...dynamicLocationConfigs,
    }),
    [dynamicLocationConfigs],
  );

  const getCurrentLocation = () => {
    const pathParts = location.pathname.split("/").filter(Boolean);
    const pathSegment = pathParts[0] || "";
    const config = allLocationConfigs[pathSegment];

    if (!config)
      return {
        main: t("locationIndicator.overview"),
        sub: t("locationIndicator.dashboard"),
      };

    return {
      main: config.label,
      sub: config.displayName || config.subpaths?.[location.pathname],
    };
  };

  const { main, sub } = getCurrentLocation();

  return (
    <div className={`flex flex-col gap-0.5 ${className}`}>
      <span className="text-sm sm:text-base font-medium text-primary truncate">
        {main}
      </span>
      {sub && (
        <span className="text-lg sm:text-2xl font-medium text-primary truncate">
          {sub}
        </span>
      )}
    </div>
  );
};

export default LocationIndicator;
