import { createApi } from "@reduxjs/toolkit/query/react";
import { ProfileResponse } from "features/profile/api/types";
import { baseQueryWithAuth } from "config/baseQuery";

export const profileSelectApi = createApi({
  reducerPath: "profileSelectApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getProfile: builder.query<ProfileResponse, boolean>({
      query: (perfil_ativo) =>
        `perfil/getListaPerfil?perfil_ativo=${perfil_ativo}`,
    }),
  }),
});

export const { useGetProfileQuery } = profileSelectApi;
