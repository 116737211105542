import { lazy } from "react";
import { RouteType } from "router/types";

const authRoutes: Array<RouteType> = [
  {
    key: "auth.login",
    path: "/login",
    component: lazy(() => import("features/auth/components/Login")),
    authority: "public",
    meta: {
      layout: "blank",
      pageContainerType: "gutterless",
    },
  },
];

export default authRoutes;
