import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Company } from "features/auth/api/types";
import {
  getSelectedCompanyStorage,
  setSelectedCompanyStorage,
  clearSelectedCompanyStorage,
} from "features/auth/components/Login/components/UserCompanyAccessModal/utils/userCompanyAccessUtil";
import {
  broadcastStateChange,
  SELECTED_COMPANY_CHANGE,
} from "features/auth/components/Login/components/UserCompanyAccessModal/utils/tabSyncUtils";

interface SelectedCompanyState {
  selectedCompany: Company | null;
  isOpen: boolean;
}

const initialState: SelectedCompanyState = {
  selectedCompany: getSelectedCompanyStorage(),
  isOpen: false,
};

const selectedCompanySlice = createSlice({
  name: "selectedCompany",
  initialState,
  reducers: {
    setSelectedCompany: (state, action: PayloadAction<Company>) => {
      state.selectedCompany = action.payload;
      // This will store both the full company object and the company code for headers
      setSelectedCompanyStorage(action.payload);
      if (!action.payload?._fromSync) {
        broadcastStateChange(SELECTED_COMPANY_CHANGE, action.payload);
      }
    },
    clearSelectedCompany: (state) => {
      state.selectedCompany = null;
      clearSelectedCompanyStorage();
      broadcastStateChange(SELECTED_COMPANY_CHANGE, null);
    },
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setSelectedCompany, clearSelectedCompany, setIsOpen } =
  selectedCompanySlice.actions;
export default selectedCompanySlice.reducer;
