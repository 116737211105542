import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserData } from "features/auth/api/types";

interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
  user: UserData | null;
  isOpen: boolean;
}

const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem("accessToken"),
  loading: false,
  error: null,
  user: null,
  isOpen: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
      localStorage.removeItem("accessToken");
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setUser: (state, action: PayloadAction<UserData | null>) => {
      state.user = action.payload;
    },
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setAuthenticated, logout, setError, setUser, setIsOpen } =
  authSlice.actions;
export default authSlice.reducer;
