import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "features/auth/api";
import { companyApi } from "features/companies/api";
import { profileSelectApi } from "features/profile/api";
import authReducer from "features/auth/slices/authSlice";
import selectedCompanyReducer from "features/auth/slices/selectedCompanySlice";
import { crudApi } from "features/crud/api";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [profileSelectApi.reducerPath]: profileSelectApi.reducer,
    [crudApi.reducerPath]: crudApi.reducer,
    auth: authReducer,
    selectedCompany: selectedCompanyReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      companyApi.middleware,
      profileSelectApi.middleware,
      crudApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
