import { useState, useEffect, useRef } from "react";
import { ChevronDown } from "lucide-react";
import { useTranslation } from "react-i18next";

export interface DropdownOption<T = string> {
  value: T;
  label: string;
  icon?: React.ReactNode;
}

interface DropdownProps<T = string> {
  placeholder?: string | React.ReactNode;
  options: DropdownOption<T>[];
  onChange: (value: T) => void;
  className?: string;
  disabled?: boolean;
  variant?: "default" | "light";
  renderOption?: (option: DropdownOption<T>) => React.ReactNode;
}

const Dropdown = <T extends string | number = string>({
  placeholder,
  options,
  onChange,
  className = "",
  disabled = false,
  variant = "default",
  renderOption,
}: DropdownProps<T>) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (option: DropdownOption<T>) => {
    onChange(option.value);
    setIsOpen(false);
  };

  const getVariantClasses = () => {
    const variants = {
      default: {
        button: "border-2 border-primary",
      },
      light: {
        button: "border-none",
      },
    };
    return variants[variant];
  };

  const variantClasses = getVariantClasses();

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <button
        type="button"
        onClick={() => !disabled && setIsOpen(!isOpen)}
        className={`
          flex items-center gap-2 w-full pr-1 pl-2
          rounded-lg
          ${variantClasses.button}
          ${disabled ? "opacity-50 cursor-not-allowed" : "hover:bg-primary-hover-light"}
        `}
        disabled={disabled}
        aria-label={t("dropdown.aria.toggle")}
      >
        <div className="flex-1 min-w-0">
          {typeof placeholder === "string" ? (
            <span className="text-text">{placeholder}</span>
          ) : (
            placeholder
          )}
        </div>
        <div className="w-8 h-8 bg-primary rounded-full flex items-center justify-center flex-shrink-0">
          <ChevronDown
            className={`w-5 h-5 text-white transition-transform duration-200 ${
              isOpen ? "rotate-180" : ""
            }`}
          />
        </div>
      </button>

      {isOpen && (
        <div className="absolute right-0 z-50 w-full mt-1 bg-light-bg rounded-lg shadow-lg border border-light-bg">
          {options.map((option) => (
            <button
              key={String(option.value)}
              type="button"
              onClick={() => handleSelect(option)}
              className="w-full px-4 py-2 text-left text-sm text-text hover:bg-primary-hover-light"
              aria-label={t("dropdown.aria.select")}
            >
              {renderOption ? (
                renderOption(option)
              ) : (
                <>
                  {option.icon && (
                    <span className="flex-shrink-0">{option.icon}</span>
                  )}
                  <span>{option.label}</span>
                </>
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
