import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import { ApiResponse, BaseEntity, CrudConfig } from "features/crud/api/types";
import { entityTransformers } from "features/crud/utils/transformers";

export const crudApi = createApi({
  reducerPath: "crudApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["CrudItem"],
  endpoints: (builder) => ({
    getList: builder.query<
      ApiResponse<BaseEntity[]>,
      {
        config: CrudConfig<BaseEntity>;
        entityType: string;
        params?: Record<string, string>;
      }
    >({
      query: ({ config, params }) => ({
        url: config.endpoints.list,
        params: params,
      }),
      providesTags: ["CrudItem"],
      transformResponse: (response: ApiResponse<BaseEntity[]>, _, arg) => ({
        ...response,
        data: response.data.map(
          (item) => entityTransformers[arg.entityType]?.response(item) ?? item,
        ),
      }),
    }),

    getById: builder.query<
      ApiResponse<BaseEntity>,
      {
        config: CrudConfig<BaseEntity>;
        id: number | string;
        entityType: string;
      }
    >({
      query: ({ config, id }) => ({
        url: `${config.endpoints.getById}/${id}`,
      }),
      providesTags: ["CrudItem"],
      transformResponse: (response: ApiResponse<BaseEntity>, _, arg) => ({
        ...response,
        data:
          entityTransformers[arg.entityType]?.response(response.data) ??
          response.data,
      }),
    }),

    create: builder.mutation<
      ApiResponse<BaseEntity>,
      { config: CrudConfig<BaseEntity>; data: any; entityType: string }
    >({
      query: ({ config, data, entityType }) => ({
        url: config.endpoints.create,
        method: "POST",
        body: entityTransformers[entityType]?.request(data) ?? data,
      }),
      invalidatesTags: ["CrudItem"],
      transformResponse: (response: ApiResponse<BaseEntity>, _, arg) => ({
        ...response,
        data:
          entityTransformers[arg.entityType]?.response(response.data) ??
          response.data,
      }),
    }),

    update: builder.mutation<
      ApiResponse<BaseEntity>,
      {
        config: CrudConfig<BaseEntity>;
        id: number | string;
        data: Partial<BaseEntity>;
        entityType: string;
      }
    >({
      query: ({ config, id, data, entityType }) => ({
        url: `${config.endpoints.update}/${id}`,
        method: "PUT",
        body: entityTransformers[entityType]?.request(data) ?? data,
      }),
      invalidatesTags: ["CrudItem"],
      transformResponse: (response: ApiResponse<BaseEntity>, _, arg) => ({
        ...response,
        data:
          entityTransformers[arg.entityType]?.response(response.data) ??
          response.data,
      }),
    }),

    delete: builder.mutation<
      ApiResponse<void>,
      {
        config: CrudConfig<BaseEntity>;
        id: number | string;
        entityType: string;
      }
    >({
      query: ({ config, id }) => ({
        url: `${config.endpoints.delete}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CrudItem"],
      transformResponse: (response: ApiResponse<BaseEntity>, _, arg) => ({
        ...response,
        data:
          entityTransformers[arg.entityType]?.response(response.data) ??
          response.data,
      }),
    }),
  }),
});

export const {
  useGetListQuery,
  useGetByIdQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
} = crudApi;
