import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useGetUserCompanyAccessQuery } from "features/auth/api";
import { Company, CompanyAccessResponse } from "features/auth/api/types";
import { setSelectedCompany } from "features/auth/slices/selectedCompanySlice";
import { useCompanyFilter } from "./useCompanyFilter";

const CONFIG = {
  USE_MOCK: true,
};

const MOCK_DATA: CompanyAccessResponse = {
  mensagem: "Sucesso",
  data: {
    usuario_id: 1,
    email_usuario: "test@example.com",
    empresas_acesso: [
      {
        empresa: {
          empresa_id: 1,
          razao_social: "CEMIG Geração Ltda",
          nome_fantasia: "",
          cnpj_empresa: "00.000.000/0001-06",
        },
      },
      {
        empresa: {
          empresa_id: 2,
          razao_social: "CEMIG 2",
          nome_fantasia: "",
          cnpj_empresa: "00.000.000/0002-06",
        },
      },
      {
        empresa: {
          empresa_id: 3,
          razao_social: "CEMIG 3",
          nome_fantasia: "",
          cnpj_empresa: "00.000.000/0003-06",
        },
      },
      {
        empresa: {
          empresa_id: 4,
          razao_social: "CEMIG 4",
          nome_fantasia: "",
          cnpj_empresa: "00.000.000/0004-06",
        },
      },
      {
        empresa: {
          empresa_id: 5,
          razao_social: "CEMIG 5",
          nome_fantasia: "",
          cnpj_empresa: "00.000.000/0005-06",
        },
      },
      {
        empresa: {
          empresa_id: 6,
          razao_social: "CEMIG 6",
          nome_fantasia: "",
          cnpj_empresa: "00.000.000/0006-06",
        },
      },
    ],
  },
};

export const useCompanySelection = (isOpen: boolean, onClose: () => void) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [startedSelection, setStartedSelection] = useState(false);

  const { data, isLoading, isError, error } = useGetUserCompanyAccessQuery(
    undefined,
    {
      skip: CONFIG.USE_MOCK,
    },
  );

  useEffect(() => {
    if (!isOpen) {
      setStartedSelection(false);
    } else {
      setStartedSelection(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (data?.data?.empresas_acesso?.length === 1) {
      handleCompanySelect(data.data.empresas_acesso[0].empresa);
      onClose();
    }
  }, [data, onClose]);

  const handleCompanySelect = (company: Company) => {
    dispatch(setSelectedCompany(company));
    navigate("/home");
  };

  const currentData = CONFIG.USE_MOCK ? MOCK_DATA : data;
  const companies = currentData?.data?.empresas_acesso || [];

  const filteredCompanies = useCompanyFilter(
    companies.map((access) => access.empresa),
    searchTerm,
  );

  return {
    companies: filteredCompanies,
    isLoading,
    handleCompanySelect,
    data: currentData,
    isError,
    error,
    searchTerm,
    setSearchTerm,
    startedSelection,
    setStartedSelection,
  };
};
